import { useBasedFetch } from "~/api/useApi";

const URLS = {
  fetchContestUrl: "/api/v1/pages/contest/",
  fetchReviewsUrl: "/api/v1/pages/reviews/",
  fetchBonusUrl: "/api/v1/bonus/",
};

export interface Contest {
  reviewsCount: number;
  money: number;
  nextUpdate: string;
  email: string;
  linkFile: string;
  linkVideo: string;
}
export interface Review {
  id: number;
  name: string;
  review: string;
  country: string;
  ip: string;
  dateTime: string;
}

export interface Reviews {
  summaryCount: number;
  reviews: Review[];
}

export interface Bonus {
  fromUsd: number;
  toUsd: number;
  percent: number;
}

export interface BonusResponse {
  fork: Bonus[];
}

export const usePagesApi = () => {
  const fetchContest = () => {
    return useBasedFetch<Contest>(URLS.fetchContestUrl);
  };

  const fetchReviews = () => {
    return useBasedFetch<Reviews>(URLS.fetchReviewsUrl, { lazy: true });
  };

  const fetchBonus = () => {
    return useBasedFetch<BonusResponse>(URLS.fetchBonusUrl);
  };

  return {
    fetchContest,
    fetchReviews,
    fetchBonus,
  };
};
